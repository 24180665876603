import { computed, inject, onMounted, onUnmounted, ref, ShallowRef, useTemplateRef } from 'vue';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { globalWindowKey } from '@/common/domain/Window';
import { loggerKey } from '@/common/domain/Logger';
import { ExclusiveUi, fromExclusive } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { exclusiveRepositoryKey } from '@/fairplayer/domain/exclusive/ExclusiveRepository';
import { Club } from '@/fairplayer/domain/club/Club';
import { maxCardSize, maxSquaresIn } from '@/fairplayer/primary/live-auctions/maxCardSize';
import { LiveAuctionsCardVue } from '@/fairplayer/primary/live-auctions/live-auctions-card';
import { LiveAuctionsExclusiveCardVue } from '@/fairplayer/primary/live-auctions/live-auctions-exclusive-card';
import { LiveAuctionsTotalPriceCardVue } from '@/fairplayer/primary/live-auctions/live-auctions-total-price-card';
import { LiveAuctionsQrCodeCardVue } from '@/fairplayer/primary/live-auctions/live-auctions-qr-code-card';
import { LiveAuctionsCardUi } from '@/fairplayer/primary/live-auctions/LiveAuctionsCard.ui';
import { LiveAuctionsClubNameCardVue } from '@/fairplayer/primary/live-auctions/live-auctions-club-name-card';
import { LiveAuctionsPlatformNameCardVue } from '@/fairplayer/primary/live-auctions/live-auctions-platform-name-card';
import { LiveAuctionsBiddersCardVue } from '@/fairplayer/primary/live-auctions/live-auctions-bidders-card';
import { LiveAuctionsBidsCardVue } from '@/fairplayer/primary/live-auctions/live-auctions-bids-card';
import { LiveAuctionsExclusivesCardVue } from '@/fairplayer/primary/live-auctions/live-auctions-exclusives-card';

export default {
  name: 'LiveAuctionsPage',

  components: {
    LiveAuctionsCardVue,
  },

  setup() {
    const clubRepository = inject(clubRepositoryKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const globalWindow = inject(globalWindowKey)!;
    const logger = inject(loggerKey)!;

    const grid: ShallowRef<HTMLElement | null> = useTemplateRef('grid');
    const club = ref();
    const previousExclusives = ref<ExclusiveUi[]>([]);
    const exclusives = ref<ExclusiveUi[]>([]);
    const refreshExclusivesIntervalId = ref(0);
    const cardPxSize = ref(0);
    const maxSquares = ref(0);

    const extraCards: LiveAuctionsCardUi[] = [
      { component: LiveAuctionsTotalPriceCardVue },
      { component: LiveAuctionsPlatformNameCardVue, head: true },
      { component: LiveAuctionsBidsCardVue },
      { component: LiveAuctionsBiddersCardVue },
      { component: LiveAuctionsClubNameCardVue },
      { component: LiveAuctionsExclusivesCardVue },
    ];

    const cards = computed<LiveAuctionsCardUi[]>(() => {
      if (!cardPxSize.value) {
        return [];
      }

      const allCards: LiveAuctionsCardUi[] = exclusives.value.map(e => ({
        component: LiveAuctionsExclusiveCardVue,
        exclusive: e,
        shaking: newBid(e.id),
      }));

      const toFill = Math.min(maxSquares.value - exclusives.value.length - 1, extraCards.length);

      for (let i = 0; i < toFill; i++) {
        const extraCard = extraCards[i];
        if (extraCard.head) {
          allCards.unshift(extraCard);
        } else {
          allCards.push(extraCard);
        }
      }

      allCards.push({ component: LiveAuctionsQrCodeCardVue });

      return allCards;
    });

    const newBid = (exclusiveId: string) =>
      previousExclusives.value.length > 0 &&
      exclusives.value.find(e => e.id === exclusiveId)!.auctionLot!.bids.length >
        (previousExclusives.value.find(e => e.id === exclusiveId)?.auctionLot?.bids?.length ?? 0);

    const sortByAuctionPriceDescThenAlphabetical = (e1: ExclusiveUi, e2: ExclusiveUi) => {
      const dif = e2.auctionLot!.currentPrice.totalCost.value - e1.auctionLot!.currentPrice.totalCost.value;
      if (dif === 0) {
        return e1.name.localeCompare(e2.name);
      }
      return dif;
    };

    const autoRefreshExclusives = (exclusives: ExclusiveUi[]) => {
      if (refreshExclusivesIntervalId.value || !exclusives.length) {
        return;
      }
      refreshExclusivesIntervalId.value = globalWindow.setInterval(loadExclusives, 4000);
    };

    const loadExclusives = async (): Promise<void> => {
      try {
        const exclusivesResponse = await exclusiveRepository.listBySlug(club.value.slug);
        const mappedExclusives = exclusivesResponse
          .filter(exclusive => exclusive.auctionLot.isPresent())
          .map(exclusive => fromExclusive(exclusive, club.value))
          .sort(sortByAuctionPriceDescThenAlphabetical);
        autoRefreshExclusives(mappedExclusives);
        const tmpExclusives = exclusives.value;
        exclusives.value = mappedExclusives;
        computeMaxCardSize();
        previousExclusives.value = tmpExclusives;
      } catch (error: any) {
        logger.error('Failed to retrieve exclusives', error);
        exclusives.value = [];
      }
    };

    const computeMaxCardSize = () => {
      const width = grid.value!.getBoundingClientRect().width;
      const height = grid.value!.getBoundingClientRect().height;
      cardPxSize.value = maxCardSize(width, height, exclusives.value.length + 1);
      maxSquares.value = maxSquaresIn(width, height, cardPxSize.value);
    };

    onMounted(() => {
      clubRepository.retrieveClub().then((retrievedClub: Club) => {
        club.value = retrievedClub;
        return loadExclusives();
      });
      globalWindow.addEventListener('resize', computeMaxCardSize);
    });

    onUnmounted(() => {
      if (refreshExclusivesIntervalId.value) {
        globalWindow.clearInterval(refreshExclusivesIntervalId.value);
      }
      globalWindow.removeEventListener('resize', computeMaxCardSize);
    });

    return {
      cards,
      grid,
      club,
      exclusives,
      cardPxSize,
    };
  },
};
