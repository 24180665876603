include ../../../../common/primary/icon/icon.mixin.pug

.fp-chat-page--chat
  form.fp-chat(v-if='conversation.isLoading()')
    .fp-chat--header
      router-link.fp-link.-no-text-decoration(:to='{name: \'membersList\'}')
        +fp-icon('arrow-left', {pointer: true})
      .fp-placeholder
    .fp-chat--messages
    .fp-chat--input
      .fp-icons-input
        input.fp-input-text(:placeholder='t(\'chat.writeMessage\')')
        .fp-icons-input--right-icon
          button.fp-icon.fp-glyph-send.-s16.-pointer
  form.fp-chat(v-if='!conversation.isLoading()' @submit.prevent='sendMessage' data-test='chat')
    .fp-chat--header
      router-link.fp-link.-no-text-decoration(:to='{name: \'membersList\'}')
        +fp-icon('arrow-left', {pointer: true})
      .fp-image.-circled
        FanImageVue(:url='conversation.value().interlocutorPictureUrl')
      span.fp-text.-text-semi-bold(data-test='interlocutor-name') {{ conversation.value().interlocutorFirstName }} {{ conversation.value().interlocutorLastName }}
    .fp-chat--messages(ref='chat-messages')
      .fp-chat--messages-notification(v-if='conversation.value().datedMessages.length === 0' data-test='empty') {{ t('chat.startOfConversation', {name: conversation.value().interlocutorFirstName}) }}
      template(v-for='(datedMessage, index) in conversation.value().datedMessages')
        .fp-chat--messages-notification(data-test='date') {{ datedMessage.date }}
        template(v-for='(message, j) in datedMessage.messages')
          .fp-horizontal-spacing.-items-end.-s-sm(v-if='message.received' :data-test='`message-${j}`')
            .fp-horizontal-spacing--column
              .fp-image.-circled
                FanImageVue(:url='conversation.value().interlocutorPictureUrl')
            .fp-horizontal-spacing--column
              .fp-card
                span.fp-text.-text-sm {{ message.text }}
          .fp-chat--message-sent(v-if='!message.received' :data-test='`message-${j}`')
            .fp-card.-dark
              span.fp-text.-text-sm {{ message.text }}
    .fp-chat--input
      .fp-icons-input
        .fp-icons-input--right-icon
          button.fp-icon.fp-glyph-send.-s16(type='submit' :disabled='!canSendMessage' :class='{"-pointer": canSendMessage, "-color-shade-400": isSending}')
        input.fp-input-text(:placeholder='t(\'chat.writeMessage\')' v-model='message' data-test='chat-input')
