include ../../../../common/primary/icon/icon.mixin.pug

.fp-card
  .fp-vertical-spacing.-s-xl.-h100.-justify-center
    .fp-vertical-spacing--line.-justify-center
      +fp-icon('euro', {size: '50'})
    .fp-vertical-spacing--line
      .fp-vertical-spacing.-s-xs
        .fp-vertical-spacing--line.-align-center
          .fp-text.-text-semi-bold.-text-xs Total
        .fp-vertical-spacing--line.-align-center
          .fp-text.-text-lg.-text-semi-bold(data-test='total-price') {{ totalPrice.text }}
