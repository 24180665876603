import { RouteLocationNormalized, Router } from 'vue-router';

interface RedirectGuard {
  fromName: string;
  toName: string;
  slugParam: string;
}

const redirectGuards: RedirectGuard[] = [
  { fromName: 'exclusiveDetail', toName: 'marketplace', slugParam: 'exclusiveSlug' },
  { fromName: 'exclusiveDetail', toName: 'donationPage', slugParam: 'exclusiveSlug' },
  { fromName: 'exclusiveDetail', toName: 'auctionsPage', slugParam: 'exclusiveSlug' },
  { fromName: 'postPage', toName: 'newsfeed', slugParam: 'postSlug' },
];

const createRedirectGuard =
  (router: Router, guard: RedirectGuard) => async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const anchorWithSlug = `#${from.params[guard.slugParam]}`;
    if (from.name === guard.fromName && to.name === guard.toName && to.hash !== anchorWithSlug) {
      await router.replace({ ...to, hash: anchorWithSlug });
    }
  };

export const createRedirectGuards = (router: Router) =>
  redirectGuards.forEach(guard => router.beforeEach(createRedirectGuard(router, guard)));
