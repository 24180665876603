import { computed, defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { FanImageVue } from '@/common/primary/fairplayer-image/fan-image';
import { InterlocutorMessagesUi } from '@/fairplayer/primary/InterlocutorMessages.ui';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'InterlocutorsListItem',

  components: {
    FanImageVue,
  },

  props: {
    conversation: {
      type: Object as PropType<InterlocutorMessagesUi>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const route = useRoute();

    const active = computed(() => route.params['memberId'] === props.conversation.interlocutorId);

    const lastMessage = computed(() => {
      const lastDayMessage = props.conversation.datedMessages[props.conversation.datedMessages.length - 1];
      return lastDayMessage.messages[lastDayMessage.messages.length - 1];
    });

    return {
      active,
      t,
      lastMessage,
    };
  },
});
