import { ExclusiveTaxDeduction } from '@/fairplayer/domain/exclusive/ExclusiveTaxDeduction';
import { FiatUi, fromFiat } from '@/common/primary/token/Fiat.ui';

export interface ExclusiveTaxDeductionUi {
  nonDeductibleAmount: FiatUi;
}

export const fromExclusiveTaxDeduction = (exclusiveTaxDeduction: ExclusiveTaxDeduction): ExclusiveTaxDeductionUi => ({
  nonDeductibleAmount: fromFiat(exclusiveTaxDeduction.nonDeductibleAmount),
});
