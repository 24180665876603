import { LocalDate } from '@/common/domain/LocalDate';
import { FiatUi, fromFiat } from '@/common/primary/token/Fiat.ui';
import { Donation } from '@/fairplayer/domain/donation/Donation';

export interface DonationUi {
  id: string;
  amount: FiatUi;
  date: string;
}

export const fromDonation = (donation: Donation, locale: string): DonationUi => ({
  id: donation.id,
  amount: fromFiat(donation.amount),
  date: LocalDate.fromDate(donation.date).toHuman(locale),
});
