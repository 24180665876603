import { useI18n } from 'vue-i18n';
import { computed, defineComponent, PropType } from 'vue';
import { ExclusiveImageVue } from '@/common/primary/fairplayer-image/exclusive-image';
import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { AuctionStatus } from '@/fairplayer/domain/exclusive/AuctionStatus';

export default defineComponent({
  name: 'LostAuctions',

  components: {
    ExclusiveImageVue,
  },

  props: {
    exclusives: {
      type: Array as PropType<ExclusiveUi[]>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const isActiveAuction = () => (e: ExclusiveUi) => e.auctionLot !== undefined && e.auctionLot.auction.status === AuctionStatus.ACTIVE;
    const hasAnyBid = () => (e: ExclusiveUi) => e.auctionLot!.bids.length > 0;
    const hasParticipated = () => (e: ExclusiveUi) => e.auctionLot!.bids.some(b => b.bidder.me);
    const isLatestBidderNotMe = () => (e: ExclusiveUi) => !e.auctionLot!.bids[0].bidder.me;

    const lostAuctions = computed(() =>
      props.exclusives.filter(isActiveAuction()).filter(hasAnyBid()).filter(hasParticipated()).filter(isLatestBidderNotMe())
    );

    return {
      t,
      lostAuctions,
    };
  },
});
