import { defineComponent, PropType } from 'vue';
import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { useI18n } from 'vue-i18n';
import { ExclusiveImageVue } from '@/common/primary/fairplayer-image/exclusive-image';
import { BidderImageVue } from '@/common/primary/auction/bidder-image';
import { TokensUi } from '@/common/primary/token/Tokens.ui';
import { fromFiat } from '@/common/primary/token/Fiat.ui';
import { euro } from '@/common/domain/token/Fiat';

export default defineComponent({
  name: 'LiveAuctionsExclusiveCard',

  components: {
    ExclusiveImageVue,
    BidderImageVue,
  },

  props: {
    exclusive: {
      type: Object as PropType<ExclusiveUi>,
      required: true,
    },
    shaking: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();

    const floor = (price: TokensUi) => fromFiat(euro(price.totalCost.value), { fiatValueDigit: 0, delimiter: '' });

    return {
      floor,
      t,
    };
  },
});
