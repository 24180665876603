router-link.fp-link.-no-text-decoration.fp-vertical-spacing.-s-sm(:to='{name: \'postPage\', params: { postSlug: post.slug }}' data-test='post-card')
  .fp-vertical-spacing--line
    .fp-image.-rounded.-expand.-wide.-hoverable
      FairplayerImageVue(:url='post.imageUrl' :alt='post.name' data-test='image')
  .fp-vertical-spacing--line
    .fp-text.-text-semi-bold.-text-md.-lines-2(data-test='name') {{ post.name }}
  .fp-vertical-spacing--line
    .fp-text.-text-md.-lines-2 {{ post.description.raw }}
  .fp-vertical-spacing--line
    .fp-text.-color-shade-500.-text-sm(data-test='date') {{ post.date }}
