include ../../../common/primary/icon/icon.mixin.pug

PageVue
  template(v-slot:body)
    .fp-vertical-spacing.-s-xl(v-if='!fan.isLoading()' data-test='profile')
      .fp-vertical-spacing--line
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line.-justify-center
            .fp-icons-image(@click='openProfilePictureModal' data-test='picture')
              .fp-image.-lg.-circled.-hoverable
                FanImageVue(:url='fan.value().identity.pictureUrl.orElse("")')
              .fp-icons-image--icon
                +fp-icon('pen', {size: '12'})
          .fp-vertical-spacing--line.-justify-center
            .fp-horizontal-spacing.-items-center.-s-sm
              .fp-horizontal-spacing--column
                h1.fp-title(data-test='name') {{ fan.value().identity.name }}
              .fp-horizontal-spacing--column
                span(:title='t("profile.verifiedUser")')
                  +fp-icon('circle-wavy-check', {size: '24', color: 'success'})
          .fp-vertical-spacing--line.-justify-center
            .fp-text(data-test='email') {{ fan.value().identity.email }}
      .fp-separator.-secondary
      .fp-vertical-spacing--line(v-if='isMarketplace')
        .fp-vertical-spacing
          .fp-vertical-spacing--line(v-if='!orderList.isLoading()')
            OrderListVue(:order-list='orderList.value()')
          .fp-vertical-spacing--line(v-if='isMarketplace && !orderList.isLoading()')
            router-link.fp-link.-no-text-decoration(:to='{name: \'clubsPage\'}')
              .fp-card(data-test='go-to-club-selection')
                .fp-text.-text-semi-bold {{ t('profile.goToClubSelection') }}
      .fp-vertical-spacing--line(v-if='isFoundation')
        .fp-vertical-spacing
          .fp-vertical-spacing--line(v-if='!exclusivePaymentDueList.isLoading() && exclusivePaymentDueList.value().length')
            ExclusivePaymentDueListVue(:exclusive-payment-due-list='exclusivePaymentDueList.value()')
          .fp-vertical-spacing--line(v-if='!orderList.isLoading()')
            OrderListVue(:order-list='orderList.value()')
          .fp-vertical-spacing--line(v-if='!donationList.isLoading()')
            DonationListVue(:donation-list='donationList.value()')
          .fp-vertical-spacing--line(v-if='club.referralNeeded' data-test='referral-list')
            ReferralListVue
  template(v-slot:footer)
    LogoutVue
