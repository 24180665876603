import { computed, defineComponent, inject, PropType, ref } from 'vue';
import { ExclusiveImageVue } from '@/common/primary/fairplayer-image/exclusive-image';
import { ExclusivePriceVue } from '@/fairplayer/primary/marketplace/exclusive-price';
import { useI18n } from 'vue-i18n';
import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { PresentationCardAuctionVue } from '@/fairplayer/primary/presentation-card/presentation-card-auction';
import { exclusiveRepositoryKey } from '@/fairplayer/domain/exclusive/ExclusiveRepository';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { ExclusiveSlug } from '@/fairplayer/domain/exclusive/ExclusiveSlug';

export default defineComponent({
  name: 'PresentationCard',

  components: { ExclusiveImageVue, ExclusivePriceVue, PresentationCardAuctionVue },

  props: {
    exclusive: {
      type: Object as PropType<ExclusiveUi>,
      required: true,
    },
    showLike: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  setup(props) {
    const { t, d } = useI18n();

    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const clubRepository = inject(clubRepositoryKey)!;

    const liked = ref<boolean | undefined>();

    const localLiked = computed(() => {
      if (liked.value !== undefined) {
        return liked.value;
      }
      return props.exclusive.isLiked;
    });

    const toggleLike = async (
      e: Event,
      isLiked: boolean,
      applyLike: (clubSlug: ClubSlug, exclusiveSlug: ExclusiveSlug) => Promise<void>
    ) => {
      e.preventDefault();
      liked.value = isLiked;

      applyLike(clubRepository.getCurrentSlug(), props.exclusive.slug).catch(() => {
        liked.value = !isLiked;
      });
    };

    const like = (e: Event) => toggleLike(e, true, (clubSlug, exclusiveSlug) => exclusiveRepository.like(clubSlug, exclusiveSlug));
    const unlike = (e: Event) => toggleLike(e, false, (clubSlug, exclusiveSlug) => exclusiveRepository.unlike(clubSlug, exclusiveSlug));

    const maskText = () => {
      if (props.exclusive.isUpcoming && props.exclusive.upcomingDate) {
        return t('marketplace.upcomingExclusiveWithDate', {
          date: d(props.exclusive.upcomingDate.toDate(), { month: 'long', day: 'numeric' }),
        });
      }
      if (props.exclusive.isUpcoming && !props.exclusive.upcomingDate) {
        return t('marketplace.upcomingExclusive');
      }
      if (!props.exclusive.visible) {
        return t('marketplace.hiddenExclusive');
      }
      if (props.exclusive.isOutOfStock) {
        return t('marketplace.outOfStock');
      }
      return undefined;
    };

    return {
      localLiked,
      like,
      unlike,
      t,
      maskText: maskText(),
    };
  },
});
