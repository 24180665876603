include ../../../common/primary/icon/icon.mixin.pug

PageVue(data-test='posts')
  template(v-slot:body)
    .fp-vertical-spacing.-s-lg
      ProgramsListVue
      .fp-vertical-spacing--line(v-if='!postList.isLoading()')
        .fp-alert.-info(v-if='!postList.value().length' data-test='alert')
          +fp-icon('info', {color: 'info', size: '18'})
          span.fp-text.-paragraph {{ t('posts.alert.emptyPosts') }}
        .fp-vertical-spacing(v-if='postList.value().length')
          .fp-vertical-spacing--line
            h2.fp-title {{ t('posts.page.newsfeed') }}
          .fp-vertical-spacing--line
            .fp-responsive-grid.-s-lg.-cols2.-cols1-up-md
              .fp-responsive-grid--column(v-for='(post, index) in highlightedPosts' :key='index' :data-test='`post-${index}`' :id='post.slug')
                PostCardVue(:post='post')
          .fp-vertical-spacing--line(v-if='postList.value().slice(2).length' data-test='other-posts')
            .fp-responsive-grid.-s-lg.-cols3.-cols2-up-md
              .fp-responsive-grid--column(v-for='(post, index) in otherPosts' :key='index' :data-test='`post-${index}`' :id='post.slug')
                PostCardVue(:post='post')
