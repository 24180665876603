import { defineComponent, PropType } from 'vue';
import { ClubUi } from '@/fairplayer/primary/club/Club.ui';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';

export default defineComponent({
  name: 'LiveAuctionsClubNameCard',

  components: {
    FairplayerImageVue,
  },

  props: {
    club: {
      type: Object as PropType<ClubUi>,
      required: true,
    },
  },
});
