include ../../../../common/primary/icon/icon.mixin.pug

.fp-vertical-spacing--line(v-if='!programsList.isLoading() && programsList.value().length' data-test='programs-list')
  .fp-responsive-grid.-cols3.-cols2-up-md
    .fp-responsive-grid--column(v-for='(program, index) in programsList.value()' :key='index' :data-test='`program-${index}`' :id='program.slug')
      router-link.fp-link.-no-text-decoration(:to='{name: \'programPage\', params: { programSlug: program.slug }}')
        .fp-featured-content.-dense
          img(:src='program.imageUrl' :alt='program.name')
          .fp-featured-content--background
          .fp-featured-content--description
            .fp-text.-text-lg.-text-md-up-lg.-text-semi-bold.-lines-2(data-test='name') {{ program.name }}
