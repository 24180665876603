import { Fiat } from '@/common/domain/token/Fiat';

export interface FiatUi {
  value: number;
  currency: string;
  text: string;
}

interface FiatUiOptions {
  fiatValueDigit?: number;
  delimiter?: string;
}

export const fromFiat = (fiat: Fiat, options?: FiatUiOptions): FiatUi => ({
  value: fiat.value,
  currency: fiat.currency,
  text: toTextWithDelimiter(fiat, options),
});

export const toTextWithDelimiter = (fiat: Fiat, options?: FiatUiOptions): string => {
  const { fiatValueDigit = 2, delimiter = ' ' } = options || {};

  const parts = fiat.value.toFixed(fiatValueDigit).toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);

  return `${parts.join('.')}${delimiter}${fiat.currency}`;
};

export const emptyFiatUi = (): FiatUi => ({
  value: 0,
  currency: '',
  text: '',
});
