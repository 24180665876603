import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { ExclusiveImageVue } from '@/common/primary/fairplayer-image/exclusive-image';

export default defineComponent({
  name: 'ExclusivePaymentDueList',

  props: {
    exclusivePaymentDueList: {
      type: Array as PropType<ExclusiveUi[]>,
      required: true,
    },
  },

  components: {
    ExclusiveImageVue,
  },

  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});
