include ../../../../common/primary/icon/icon.mixin.pug

.fp-dynamic-card(data-test='exclusive-card')
  .fp-dynamic-card--image
    ExclusiveImageVue(:url='exclusive.imageUrl')
    .fp-dynamic-card--image-badge(v-if='exclusive.auctionLot.bids.length > 0' :class='{"-success": shaking, "-shaking": shaking}' data-test='active-last-bid')
      .fp-image.-circled.-sm
        BidderImageVue(:bidder='exclusive.auctionLot.bids[0].bidder')
      span.fp-text.-text-semi-bold.-text-xl(data-test='pricing') {{ floor(exclusive.auctionLot.bids[0].price).text }}
    .fp-dynamic-card--image-badge(v-if='exclusive.auctionLot.bids.length === 0' data-test='active-no-bid')
      +fp-icon('gavel', { size: '16', color: 'shade-500' })
      span.fp-text.-text-semi-bold.-text-xl(data-test='pricing') {{ floor(exclusive.pricing).text }}
  .fp-dynamic-card--left-tag
    span.fp-tag(v-if='exclusive.auctionLot.number' data-test='lot-number')
      span.fp-tag--text {{ t('marketplace.auction.lot') }} {{ exclusive.auctionLot.number }}
  .fp-dynamic-card--details
    .fp-dynamic-card--details--text(:title='exclusive.name') {{ exclusive.name }}
