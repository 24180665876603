include ../../../../../../common/primary/icon/icon.mixin.pug

.fp-vertical-spacing.-s-sm
  .fp-vertical-spacing--line
    h1.fp-title(data-test='exclusive-name') {{ exclusive.name }}
  .fp-vertical-spacing--line(v-if='!club.isPopup || exclusive.auctionLot?.number')
    .fp-horizontal-spacing.-s-sm
      .fp-horizontal-spacing--column(v-if='exclusive.auctionLot?.number')
        span.fp-tag(data-test='lot-number')
          span.fp-tag--text {{ t('marketplace.auction.lot') }} {{ exclusive.auctionLot.number }}
      .fp-horizontal-spacing--column(v-if='!club.isPopup')
        span.fp-tag(data-test='exclusive-category')
          i.fp-icon(:class='exclusive.categoryIcon')
          span.fp-tag--text {{ t(`marketplace.categories.${exclusive.category.toLowerCase()}`) }}
  template(v-if='club.isFoundation')
    .fp-vertical-spacing--line
      .fp-alert.-info
        +fp-icon('info', {color: 'info', size: '18'})
        template(v-if='exclusive.auctionLot')
          span.fp-text.-paragraph(v-if='exclusive.auctionLot.auction.status === \'CLOSED\'' data-test='exclusive-closed-auction') {{ t('marketplace.exclusiveDetail.auction.status.closed') }}
          span.fp-text.-paragraph(v-else data-test='exclusive-participate-auction') {{ t('marketplace.exclusiveDetail.auction.participate') }}
        template(v-if='!exclusive.auctionLot')
          span.fp-text.-paragraph(v-if='exclusive.category === \'COLLECTIBLE\'' data-test='collectible-one-time-donation') {{ t('marketplace.exclusiveDetail.oneTimeDonationCollectible') }}
          span.fp-text.-paragraph(v-else data-test='experience-one-time-donation') {{ t('marketplace.exclusiveDetail.oneTimeDonationExperience') }}
  .fp-vertical-spacing--line(v-if='exclusive.auctionLot && exclusive.auctionLot.bids && exclusive.auctionLot.bids.length' data-test='current-price')
    .fp-vertical-spacing.-s-xs
      .fp-vertical-spacing--line
        h2.fp-title(v-if='exclusive.auctionLot.auction.status === \'CLOSED\'' data-test='auction-won-label') {{ t('marketplace.auction.wonBy') }}
        h2.fp-title(v-else data-test='auction-current-bid-label') {{ t('marketplace.exclusiveDetail.auction.currentPrice') }}
      .fp-vertical-spacing--line
        ExclusiveAuctionBidVue(highlighted :bid='exclusive.auctionLot.bids[0]')
  .fp-vertical-spacing--line.-hidden-up-md(v-if='!exclusive.auctionLot' data-test='heading-price')
    ExclusivePriceVue(:pricing='exclusive.pricing' xl)
