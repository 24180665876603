include ../../../common/primary/icon/icon.mixin.pug

.fp-collapsible-card(data-test='orders')
  input#order-list(type='checkbox', name='collapse')
  label.fp-collapsible-card--trigger(for='order-list' data-test='orders.trigger')
    template(v-if='club.isMarketplace')
      +fp-icon('shopping-bag', {size: '16'})
    template(v-else)
      +fp-icon('gift', {size: '16'})
    span.fp-text.-text-semi-bold {{ t(club.isMarketplace ? 'orders.myOrders' : 'orders.myExclusives') }}
    +fp-icon('chevron-right', {size: '12'})
  .fp-collapsible-card--content
    .fp-vertical-spacing.-s-lg
      .fp-vertical-spacing--line(v-if='!hasOrders()')
        .fp-text(v-if='club.isMarketplace' data-test='no-orders') {{ t('orders.noOrders') }}
        .fp-text(v-else data-test='no-exclusives') {{ t('orders.noExclusives') }}
      template(v-for='(order, orderIdx) in orderList' :key='`order-${orderIdx}`')
        .fp-vertical-spacing.-s-sm(v-for='(exclusive, exclusiveIdx) in order.exclusives' :key='`order-${orderIdx}-exclusive-${exclusiveIdx}`' :data-test='`order-${orderIdx}-exclusive-${exclusiveIdx}`')
          .fp-vertical-spacing--line
            .fp-illustrated-item
              .fp-image.-lg.-rounded
                ExclusiveImageVue(:url='exclusive.imageUrl' highlightable data-test='image')
              .fp-illustrated-item--content
                .fp-vertical-spacing.-s-xs
                  .fp-vertical-spacing--line
                    .fp-text.-text-semi-bold(data-test='name') {{ exclusive.name }}
                  .fp-vertical-spacing--line
                    .fp-text.-color-shade-500.-text-sm(data-test='date') {{ order.date }}
                  .fp-vertical-spacing--line
                    OrderPriceVue(:order='order')
          .fp-vertical-spacing--line
            a.fp-link(@click='print(exclusive)' data-test='print')
              .fp-icon-with-text
                +fp-icon('print', {size: '16'})
                .fp-icon-with-text--text
                  span.fp-text.-color-shade-600.-text-sm {{ t('orders.print') }}
      .fp-vertical-spacing--line(v-if='club.isMarketplace')
        a.fp-link(@click='goToMarketplace' data-test='redirect-to-marketplace') {{ t('orders.goToMarketplace') }}
      .fp-vertical-spacing--line(v-else)
        a.fp-link(@click='goToDonationPage' data-test='redirect-to-donation-page') {{ t('orders.makeOneTimeDonation') }}
