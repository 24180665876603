import { defineComponent, PropType } from 'vue';
import { FairplayerFallbackImageUi } from '@/common/primary/fairplayer-image/FairplayerFallbackImage.ui';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';

export default defineComponent({
  name: 'ExclusiveImage',

  props: {
    highlightable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    contain: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    lazy: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    url: {
      type: String as PropType<string>,
      default: '',
      required: false,
    },
  },

  components: { FairplayerImageVue },

  emits: ['load'],

  setup(props, { emit }) {
    const onLoad = () => emit('load');

    return {
      onLoad,
      FairplayerFallbackImageUi,
    };
  },
});
